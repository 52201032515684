import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { addScript } from './addScript'
import { removeScript } from './removeScript'

export const useAccessibilityPlugin = (content: string) => {
  const { locale, reload } = useRouter()

  const scriptId = 'accessibilityPlugin-script'

  useEffect(() => {
    if (locale === 'he-IL') {
      addScript(scriptId, content)
    } else {
      removeScript(scriptId, () => reload())
    }
  }, [content, locale, reload])
}
