import type {
  LogsInitConfiguration,
  RumErrorEvent,
  RumEvent,
  RumInitConfiguration,
} from '@knauf-group/ct-shared-nextjs/datadog'

const regex = /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/

const isRumErrorEvent = (event: RumEvent): event is RumErrorEvent => event?.type === 'error'

export const beforeSendBrowserLogs: LogsInitConfiguration['beforeSend'] = (event, context) => {
  if (
    event.http?.status_code === 0 ||
    (context && 'isAborted' in context && context?.isAborted)
  ) {
    return false
  }

  if (regex.test(event.message) || regex.test(event.error?.message)) {
    return false
  }

  return true
}

export const beforeSendRum: RumInitConfiguration['beforeSend'] = (event, context) => {
  if (context && 'isAborted' in context && context?.isAborted) {
    return false
  }

  if (isRumErrorEvent(event) && regex.test(event.error.message)) {
    return false
  }

  return true
}
