import '@cms/services/datadog'

import { useEffect } from 'react'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import App from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { Session } from 'next-auth'
import type { SSRConfig } from 'next-i18next'
import { appWithTranslation } from 'next-i18next'
import nextI18next from 'next-i18next.config'

import env from '@beam-australia/react-env'
import ErrorBoundary from '@cms/components/ErrorBoundary'
import { useAccessibilityPlugin } from '@cms/hooks/useAccessibilityPlugin'
import { ADOBE_LAUNCH_SCRIPT_URL } from '@cms/utils/constants'
import { getFontVariant } from '@cms/utils/fontVariant'
import { isStaticallyGeneratedPage } from '@cms/utils/isStaticallyGeneratedPage'
import type { CMSPageProps } from '@cms/utils/types'
import { getEmotionCache } from '@cms/utils/utils'
import HubSpotProvider from '@components/HubSpotForm/HubSpotProvider'
import type { AnalyticsConfig } from '@knauf-group/cst-analytics'
import { AnalyticsProvider } from '@knauf-group/cst-analytics'
import { getThemeByLocale } from '@knauf-group/ct-designs/themeConfigs/rebranding/theme'
import { getDirectionAttribute } from '@knauf-group/ct-designs/utils/utils'
import { useSetDir } from '@knauf-group/ct-shared-nextjs/client'
import SessionProvider from '@knauf-group/ct-shared-nextjs/nextauth/react'
import {
  type DeviceType,
  inferDeviceType,
} from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { createGlobalThemeBasedOnDevice } from '@knauf-group/ct-shared-nextjs/utils/theme'
import { useSetNextLocaleCookie } from '@knauf-group/ct-shared-nextjs/web/hooks'
import generateAccessibilityPluginScript from '@knauf-group/ct-shared-nextjs/web/utils/generateAccessibilityPluginScript'
import { Logger } from '@lib/logger'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import type { IToggle } from '@unleash/nextjs'
import { FlagProvider, getFrontendFlags } from '@unleash/nextjs/client'

// TODO: remove ones all components switched from this font
import '@knauf-group/ct-designs/public/fonts/fonts.css'

const GlobalContextProvider = dynamic(() =>
  import('../cms/context/globalContext').then((mod) => mod.GlobalContextProvider),
)

type PageProps = Partial<CMSPageProps> & SSRConfig & { session: Session }

type AppOwnProps = {
  deviceType: DeviceType
  toggles: IToggle[]
}

type CustomAppProps = AppProps<PageProps> & AppOwnProps

const AppTranslated = appWithTranslation((props: AppProps) => {
  const { Component, pageProps } = props

  const ACCESSIBILITY_PLUGIN_SITEKEY = env('ACCESSIBILITY_PLUGIN_SITEKEY')

  if (ACCESSIBILITY_PLUGIN_SITEKEY) {
    useAccessibilityPlugin(generateAccessibilityPluginScript(ACCESSIBILITY_PLUGIN_SITEKEY))
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="google-site-verification"
          content="dM5H6eBZYcaez4kX_7J6w4-VWigInUdY9KJRmC6R06k"
        />
      </Head>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  )
}, nextI18next)

const HubSpotProviderWrapper = ({ children, hasContentFormFields }) =>
  hasContentFormFields ? <HubSpotProvider>{children}</HubSpotProvider> : children

const analyticsConfig: AnalyticsConfig = {
  appName: 'knauf_cms',
  adobeToken: ADOBE_LAUNCH_SCRIPT_URL,
}

const AppWithFlagProvider = ({ toggles, ...props }: CustomAppProps) => {
  const router = useRouter()
  return (
    !isStaticallyGeneratedPage(router.pathname) && (
      <FlagProvider
        config={{
          url: env('UNLEASH_FRONTEND_API_URL'),
          clientKey: env('UNLEASH_FRONTEND_API_TOKEN'),
          appName: env('UNLEASH_APP_NAME'),
          bootstrap: toggles,
          disableRefresh: true,
          disableMetrics: true,
        }}
      >
        <AppTranslated {...props} />
      </FlagProvider>
    )
  )
}

const AppWithoutFlagProvider = (props: CustomAppProps) => {
  const router = useRouter()
  return isStaticallyGeneratedPage(router.pathname) && <AppTranslated {...props} />
}

const CMSApp = (props: CustomAppProps) => {
  const router = useRouter()
  const { deviceType, pageProps, toggles } = props
  const direction = getDirectionAttribute(router.locale)
  const isRTL = direction === 'rtl'

  const themeByLocale = getThemeByLocale(router?.locale)
  const FontVariant = getFontVariant(themeByLocale.fontToUse)

  useSetNextLocaleCookie(router.locale)

  useEffect(() => {
    if (router.isReady) {
      document.documentElement.lang = router.locale
    }
  }, [router.asPath, router.isReady, router.locale])

  useSetDir()

  return (
    <AppCacheProvider {...props} emotionCache={getEmotionCache(isRTL)}>
      <GlobalContextProvider>
        <ThemeProvider
          theme={createGlobalThemeBasedOnDevice(deviceType, themeByLocale.theme, direction)}
        >
          <CssBaseline />
          <FontVariant />
          <AnalyticsProvider config={analyticsConfig}>
            <HubSpotProviderWrapper hasContentFormFields={pageProps?.hasContentFormFields}>
              <SessionProvider session={pageProps.session} showLoadingSessionSpinner={false}>
                <AppWithFlagProvider toggles={toggles} {...props} />
                <AppWithoutFlagProvider {...props} />
              </SessionProvider>
            </HubSpotProviderWrapper>
          </AnalyticsProvider>
        </ThemeProvider>
      </GlobalContextProvider>
    </AppCacheProvider>
  )
}

async function getFrontendFlagsWithFallback() {
  try {
    return await getFrontendFlags()
  } catch (error) {
    Logger.error(`[app] Error fetching feature flags: ${error.message}`, error)
    return { toggles: [] }
  }
}

CMSApp.getInitialProps = async (
  context: AppContext,
): Promise<Partial<CustomAppProps> & AppInitialProps> => {
  let deviceType: DeviceType = 'desktop' // fallback to desktop

  if (context.ctx.req) {
    const userAgent = context.ctx.req.headers['user-agent']
    deviceType = inferDeviceType(userAgent ?? '')
  }

  if (isStaticallyGeneratedPage(context.router.pathname)) {
    return { ...(await App.getInitialProps(context)) }
  }

  const [ctx, { toggles }] = await Promise.all([
    App.getInitialProps(context),
    getFrontendFlagsWithFallback(),
  ])
  return { ...ctx, deviceType, toggles }
}

export default CMSApp
